<template>
  <div class="container">
    <div class="home">
      <VideoPlayer v-if="isShowPCVideo" @close="isShowPCVideo = false" :videoSrc="videoSrc"></VideoPlayer>
      <!--  START  轮播图区域-->
      <div class="swiper">
        <div class="txt">
          <span class="title" :style="{ color: '#FFFFFF' }">突破 创新</span><br>
          <span class="desc" :style="{ color: '#FFFFFF' }">打造中国山地生态农牧领军企业</span><br>
          <!-- <span class="title" :style="{color: $store.state.isBlackText ? '#292A34' : '#FFFFFF'}">突破 创新</span><br> -->
          <!-- <span class="desc" :style="{color: $store.state.isBlackText ? '#292A34' : '#FFFFFF'}">打造中国山地生态农牧领军企业</span><br> -->
          <span class="btn" @click="playVideo('pc')">
            <img src="../assets/images/play_btn.png" alt="">
            <span>观看视频</span>
          </span>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-no-swiping">
            <img src="../assets/images/index_bg1.png" alt="">
          </div>
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg01.png" alt="">-->
          <!--        </div>-->
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg02.png" alt="">-->
          <!--        </div>-->
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg03.png" alt="">-->
          <!--        </div>-->
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg04.png" alt="">-->
          <!--        </div>-->
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <!--  END  轮播图区域-->
      <!--  START  NEWS区域-->
      <div class="news">
        <div class="news_txt"><img src="../assets/images/News.png" alt=""></div>
        <div class="cns animate__animated animate__slideInUp">新闻中心</div>
        <div class="news_list">
          <div class="item animate__animated animate__slideInUp" v-for="(item, index) in newsList.slice(0, 3)" :key="index" @click="goDetail(item)">
            <span style="font-size: 36px; color: #387DEA; font-family: 'Impact';">{{ item.tTime.split('-')[2] }}</span><span>{{ '/' + item.tTime.split('-')[1] + '月' }}</span><span class="news_desc" style="margin-left: 31px;">{{ item.tName }}</span>
          </div>
        </div>
      </div>
      <!--  END  NEWS区域-->
      <!--  START  产品区域-->
      <div class="product">
        <div class="up">
          <div class="item" @click="goPage('生猪养殖', 'pig')">
            <div class="title">生猪</div>
            <div class="desc">养殖</div>
            <img class="line" src="../assets/images/line@2x.png" alt="line">
            <img class="zz" src="../assets/images/zz_01.png" alt="zz">
            <img class="num" src="../assets/images/01@2x.png" alt="num">
            <img class="prod" src="../assets/images/prod_01.png" alt="prod">
          </div>
          <div class="item" @click="goPage('饲料生产', 'feed')">
            <div class="title">饲料</div>
            <div class="desc" style="margin-left: 3px;">生产</div>
            <img class="line" style="margin-left: 3px;" src="../assets/images/line@2x.png" alt="line">
            <img class="zz" src="../assets/images/zz_02.png" alt="zz">
            <img class="num" src="../assets/images/02@2x.png" alt="num">
            <img class="prod" src="../assets/images/prod_02.png" alt="prod">
          </div>
        </div>
        <div class="down">
          <div class="item" @click="goPage('原料贸易', 'trade')">
            <div class="title">原料</div>
            <div class="desc">贸易</div>
            <img class="line" src="../assets/images/line@2x.png" alt="line">
            <img class="zz" src="../assets/images/zz_03.png" alt="zz">
            <img class="num" src="../assets/images/03@2x.png" alt="num">
            <img class="prod" src="../assets/images/prod_03.png" alt="prod">
          </div>
          <div class="item" @click="goPage('食品加工', 'food')">
            <div class="title">食品</div>
            <div class="desc" style="margin-left: 3px;">加工</div>
            <img class="line" style="margin-left: 3px;" src="../assets/images/line@2x.png" alt="line">
            <img class="zz" src="../assets/images/zz_04.png" alt="zz">
            <img class="num" src="../assets/images/04@2x.png" alt="num">
            <img class="prod" src="../assets/images/prod_04.png" alt="prod">
          </div>
          <div class="item" @click="goPage('投资业务', 'investment')">
            <div class="title">投资</div>
            <div class="desc">业务</div>
            <img class="line" src="../assets/images/line@2x.png" alt="line">
            <img class="zz" src="../assets/images/zz_05.png" alt="zz">
            <img class="num" src="../assets/images/05@2x.png" alt="num">
            <img class="prod" src="../assets/images/prod_05.png" alt="prod">
          </div>
        </div>
      </div>
      <!--  END  产品区域-->
    </div>
    <div class="mHome">
      <VideoPlayer v-if="isShowVideo" @close="isShowVideo = false" :videoSrc="videoSrc"></VideoPlayer>
      <!--  START  轮播图区域-->
      <div class="swiper-m">
        <div class="txt">
          <!-- <span class="title" :style="{color: $store.state.isBlackText ? '#292A34' : '#FFFFFF'}">突破 创新</span>
          <span class="desc" :style="{color: $store.state.isBlackText ? '#292A34' : '#FFFFFF'}">打造中国山地生态农牧领军企业</span> -->
          <span class="title" :style="{ color: '#FFFFFF' }">突破 创新</span>
          <span class="desc" :style="{ color: '#FFFFFF' }">打造中国山地生态农牧领军企业</span>
          <div class="btn" @click="playVideo(null)">
            <img src="../assets/images/play_btn.png" alt="">
            <span>观看视频</span>
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-no-swiping">
            <img src="../assets/images/index_bg1.png" alt="">
          </div>
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg01.png" alt="">-->
          <!--        </div>-->
          <!-- <div class="swiper-slide"> -->
          <!-- <img src="../assets/images/bg02.png" alt=""> -->
          <!-- </div> -->
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg03.png" alt="">-->
          <!--        </div>-->
          <!--        <div class="swiper-slide">-->
          <!--          <img src="../assets/images/bg04.png" alt="">-->
          <!--        </div>-->
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <!--  END  轮播图区域-->
      <!--  START  NEWS区域-->
      <div class="news">
        <div class="cns">新闻中心</div>
        <div class="news_list">
          <div class="item" v-for="(item, index) in newsList.slice(0, 3)" :key="index" @click="goDetail(item)">
            <span class="tName">{{ item.tName }}</span><span class="tTime">{{ item.tTime.replace(/-/g, '.') }}</span>
          </div>
        </div>
      </div>
      <!--  END  NEWS区域-->
      <!--  START  产品区域-->
      <div class="product">
        <div class="item" @click="goPage('原料贸易', 'trade')">
          <!--        <div class="leftup">-->
          <!--          <div class="title">原料</div>-->
          <!--          <div class="desc">贸易</div>-->
          <!--          <img class="line" src="../assets/images/line@2x.png" alt="line">-->
          <!--        </div>-->
          <!--        <img class="zz" src="../assets/images/zz_01_m.png" alt="zz">-->
          <!--        <img class="num" src="../assets/images/01@2x.png" alt="num">-->
          <img class="zz" src="../assets/images/trade_m.png" alt="">
          <img class="prod" src="../assets/images/prod_01_m.png" alt="prod">
        </div>
        <div class="item" @click="goPage('饲料生产', 'feed')">
          <!--        <div class="leftup">-->
          <!--          <div class="title">饲料</div>-->
          <!--          <div class="desc">生产</div>-->
          <!--          <img class="line" src="../assets/images/line@2x.png" alt="line">-->
          <!--        </div>-->
          <img class="zz" style="width: 3.3rem;" src="../assets/images/feed_m.png" alt="zz">
          <!--        <img class="num" src="../assets/images/02@2x.png" alt="num">-->
          <img class="prod" src="../assets/images/prod_02_m.png" alt="prod">
        </div>
        <div class="item" @click="goPage('生猪养殖', 'pig')">
          <!--        <div class="leftup">-->
          <!--          <div class="title">生猪</div>-->
          <!--          <div class="desc">养殖</div>-->
          <!--          <img class="line" src="../assets/images/line@2x.png" alt="line">-->
          <!--        </div>-->
          <img class="zz" src="../assets/images/pig_m.png" alt="zz">
          <!--        <img class="num" src="../assets/images/01@2x.png" alt="num">-->
          <img class="prod" src="../assets/images/prod_03_m.png" alt="prod">
        </div>
        <div class="item" @click="goPage('食品加工', 'food')">
          <!--        <div class="leftup">-->
          <!--          <div class="title">食品</div>-->
          <!--          <div class="desc">加工</div>-->
          <!--          <img class="line" src="../assets/images/line@2x.png" alt="line">-->
          <!--        </div>-->
          <img class="zz" src="../assets/images/food_m.png" alt="zz">
          <!--        <img class="num" src="../assets/images/04@2x.png" alt="num">-->
          <img class="prod" src="../assets/images/prod_04_m.png" alt="prod">
        </div>
        <div class="item" @click="goPage('投资业务', 'investment')">
          <!--          <div class="leftup">-->
          <!--            <div class="title">投资</div>-->
          <!--            <div class="desc">业务</div>-->
          <!--            <img class="line" src="../assets/images/line@2x.png" alt="line">-->
          <!--          </div>-->
          <img class="zz" src="../assets/images/investment_m.png" alt="zz">
          <!--          <img class="num" src="../assets/images/05@2x.png" alt="num">-->
          <img class="prod" src="../assets/images/prod_05_m.png" alt="prod">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsPage } from "@/api/interface"
import VideoPlayer from "@/components/mp4Player.vue"

export default {
  name: 'Home',
  components: {
    VideoPlayer
  },
  data() {
    return {
      newsList: [],
      param: {
        pageNum: 1,
        pageSize: 3
      },
      isShowPCVideo: false,
      isShowVideo: false,
      currentScroll: 0,
      videoSrc: '',
    }
  },
  watch: {
    isShowPCVideo: {
      handler(val) {
        if (val) document.documentElement.style.overflow = 'hidden';
        else document.documentElement.style.overflow = 'auto';
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      (this.deviceWidth > 1074) && this.initSwiper()
      (this.deviceWidth <= 1074) && this.initMSwiper()
    })
    this.getNews()
  },
  methods: {
    // 初始化轮播图
    initSwiper() {
      const _this = this
      var swiper = new Swiper('.swiper', {
        // autoplay: true,
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
        }
      })
      swiper.on('slideChangeTransitionEnd', function () {
        _this.$store.dispatch('UPDATE_BLACK', swiper.realIndex === 0 ? true : false)
      })
    },
    // 初始化轮播图
    initMSwiper() {
      const _this = this
      var swiper = new Swiper('.swiper-m', {
        // autoplay: true,
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
        }
      })
      swiper.on('slideChangeTransitionEnd', function () {
        _this.$store.dispatch('UPDATE_BLACK', swiper.realIndex === 0 ? true : false)
      })
    },
    // 获取新闻列表
    getNews() {
      getNewsPage(this.param).then(res => {
        if (res.code === 200) {
          this.newsList = res.rows
        }
      })
    },
    // 跳转页面
    goPage(title, page) {
      this.$router.push({
        name: (this.deviceWidth <= 1074) ? (page == 'investment' ? 'm-developer' : 'm-other') : 'other',
        params: { page, title }
      })
    },
    // 播放视频
    playVideo(platform) {
      this.videoSrc = 'https://forryed.forryed.com/%E5%AF%8C%E4%B9%8B%E6%BA%90%E9%9B%86%E5%9B%A2.mp4'
      if (platform) this.isShowPCVideo = true
      else this.isShowVideo = true
    },
    // 跳转到详情
    goDetail(item) {
      this.$router.push({
        name: 'articleDetail',
        params: {
          data: JSON.stringify(item)
        }
      })
    },
    // 屏幕滚动
    scrollFunction() {
      this.currentScroll = window.pageYOffset
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1074px) {
  .home {
    width: 100%;
    font-size: 0;
    ::v-deep .player {
      .easy-player {
        width: 1270px;
        height: 720px;

        .video-title {
          font-size: 15px;
        }
      }
    }
    .swiper {
      height: 655px;
      position: relative;

      .txt {
        position: absolute;
        top: 211px;
        left: 108px;
        z-index: 2;
        color: #FFFFFF;

        >span {
          display: inline-block;
          color: #FFFFFF;

          &.btn {
            margin-top: 26px;
            width: 127px;
            height: 36px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 12px;
            padding-right: 21px;
            box-sizing: border-box;
            color: #292A34;
            font-size: 16px;
            cursor: pointer;

            img {
              width: 18px;
              height: 18px;
            }
          }

          &.title {
            font-size: 32px;
            font-weight: 700;
          }

          &.desc {
            margin-top: 3px;
            font-size: 24px;
          }
        }
      }

      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 100%;
            height: 655px;
          }
        }
      }
    }
    .news {
      position: relative;
      height: 548px;
      background-color: #fff;
      .cns {
        font-size: 36px;
        position: absolute;
        left: 327px;
        top: 130px;
        font-family: "PingFang SCSB";
      }
      .news_list {
        position: absolute;
        top: 204px;
        left: 455px;
        .item {
          font-size: 18px;
          line-height: 80px;
          border-bottom: 0.5px solid rgba(151, 151, 151, 0.25);
          font-family: "PingFang SCR";
          min-width: 140px;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .product {
      display: flex;
      flex-direction: column;
      color: #FFFFFF;
      .up,
      .down {
        display: flex;
        .item {
          position: relative;
          //height: 616px;
          display: inline-block;
          overflow: hidden;
          cursor: pointer;
          transition: all 1s;
          font-family: "PingFang SCSB";
          .prod {
            transition: all 1s;
          }
          &:hover {
            .prod {
              transform: scale(1.1);
            }
          }
          div,
          .line,
          .num,
          .zz {
            position: absolute;
            z-index: 99;
          }
          div,
          .line {
            left: 100px;
          }
          .num {
            right: 0;
            bottom: 0;
          }
          div {
            z-index: 99;

            &.title {
              font-size: 32px;
              top: 74px;
              z-index: 100;
            }

            &.desc {
              font-size: 22px;
              top: 122px;
              font-family: 'PingFang SCR';
              font-weight: 400;
              z-index: 100;
            }
          }

          .line {
            top: 176px;
            z-index: 99;
          }
        }
      }
      .up {
        .item {
          width: 50%;
          .zz, .prod {
            width: 100%;
          }
        }
      }
      .down {
        .item {
          width: 33.3333333333333333%;
          img.zz,
          img.prod {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .mHome {
    display: none;
  }
}
@media screen and (max-width: 1074px) {
  .home {
    display: none;
  }
  .mHome {
    ::v-deep .player {
      .video {
        i {
          height: 0.6rem;
          bottom: 8%;
          left: 50%;
          transform: translateX(-50%);
        }
        .easy-player {
        }
      }
    }

    .swiper-m {
      position: relative;
      overflow: hidden;

      .txt {
        position: absolute;
        top: 1.8rem;
        left: 0.64rem;
        z-index: 80;
        color: #FFFFFF;
        user-select: none;

        >span {
          display: block;

          &.title {
            font-size: 0.36rem;
            font-weight: 600;
          }

          &.desc {
            font-size: 0.24rem;
          }
        }

        .btn {
          width: 0.98rem;
          height: 0.38rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          color: #292A34;
          margin-top: 0.26rem;
          padding-left: 0.08rem;
          padding-right: 0.18rem;

          img {
            width: 0.22rem;
            height: 0.22rem;
          }

          >span {
            font-size: 0.16rem;
          }
        }
      }

      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 100%;
            height: 4.52rem;
          }
        }
      }

      .swiper-pagination {
        bottom: -0.32rem;
        width: 100%;

        ::v-deep .swiper-pagination-bullet {
          background: transparent;
          border: 0.02rem solid #979797;
          width: 0.12rem;
          height: 0.12rem;
          box-sizing: border-box;

          &.swiper-pagination-bullet-active {
            background-color: #D8D8D8;
            border: none;
          }
        }
      }
    }

    .news {
      margin-top: 0.24rem;
      padding: 0 0 0.86rem 0.32rem;
      box-sizing: border-box;

      .cns {
        font-size: 0.36rem;
        font-weight: 600;
      }

      .news_list {
        font-size: 0.24rem;
        margin-top: 0.56rem;

        .item {
          display: flex;
          justify-content: space-between;
          padding: 0 0.42rem 0 0.32rem;
          margin-bottom: 0.34rem;

          .tName {
            width: 4.76rem;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            color: #000000;
          }

          .tTime {
            color: #7F7F7F;
          }
        }
      }
    }

    .product {
      color: #FFFFFF;
      .item {
        position: relative;
        width: 100%;
        height: 3.78rem;
        overflow: hidden;

        .zz,
        .num {
          position: absolute;
        }

        .zz {
          width: 3.28rem;
          height: 3.88rem;
          bottom: -0.04rem;
          right: -0.1rem;
        }

        //.num {
        //  width: 1.68rem;
        //  height: 1.26rem;
        //  bottom: 0;
        //  right: 0;
        //  z-index: 2;
        //}
        .prod {
          width: 100vw;
          height: 100%;
        }
      }
    }
  }
}
</style>
