import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: { title: '贵州富之源集团官方网站' },
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: '贵州富之源集团官方网站-关于富之源' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: { title: '贵州富之源集团官方网站-新闻中心' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/responsibility',
    name: 'responsibility',
    meta: { title: '贵州富之源集团官方网站-社会责任' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Responsibility.vue')
  },
  {
    path: '/developer',
    name: 'developer',
    meta: { title: '贵州富之源集团官方网站-产业发展' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Developer.vue')
  },
  {
    path: '/culture',
    name: 'culture',
    meta: { title: '贵州富之源集团官方网站-企业文化' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Culture.vue')
  },
  {
    path: '/join',
    name: 'join',
    meta: { title: '贵州富之源集团官方网站-加入我们' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Join.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { title: '贵州富之源集团官方网站-联系我们' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    meta: { title: '贵州富之源集团官方网站-文章详情页' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleDetail.vue')
  },
  {
    path: '/other',
    name: 'other',
    meta: { title: '贵州富之源集团官方网站-板块详情' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Other.vue')
  },
  {
    path: '/mOther/:page',
    name: 'm-other',
    meta: { title: '贵州富之源集团官方网站' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Mother.vue')
  },
  {
    path: '/mDeveloper/:page',
    name: 'm-developer',
    meta: { title: '贵州富之源集团官方网站' },
    component: () => import(/* webpackChunkName: "about" */ '../views/MDeveloper.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.name != 'home') {
    store.dispatch('UPDATE_BLACK', false)
  } else {
    store.dispatch('UPDATE_BLACK', true)
  }
  document.documentElement.scrollTop = 0
  next()
})

export default router
