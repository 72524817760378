import request from '@/utils/request'

// 获取企业文化照片墙
export function getActionHome(params) {
    return request({
        url: 'prod-api/generator/actionHome/list',
        method: 'GET',
        params
    })
}

// 获取新闻中心
export function getNewsPage(params) {
    return request({
        url: 'prod-api/generator/news/list',
        method: 'GET',
        params
    })
}

// 获取置顶新闻数
export function getTopNewsNum(params) {
    return request({
        url: 'prod-api/generator/newsNum/list',
        method: 'GET',
        params
    })
}

// 获取发展历程
export function getDeveloper(params) {
    return request({
        url: 'prod-api/generator/evolutionHistory/list',
        method: 'GET',
        params
    })
}

// 获取荣誉资质
export function getHorner(params) {
    return request({
        url: 'prod-api/generator/honorsQualification/list',
        method: 'GET',
        params
    })
}

// 底部数据
export function getBottomData(params) {
    return request({
        url: 'prod-api/generator/companyDetails/list',
        method: 'GET',
        params
    })
}

// 社会责任数据
export function getResponsebility(params) {
    return request({
        url: 'prod-api/generator/shText/list',
        method: 'GET',
        params
    })
}

// 获取原料贸易数据
export function getYL(params) {
    return request({
        url: 'prod-api/generator/ylText/list',
        method: 'GET',
        params
    })
}

// 获取原料贸易产品数据
export function getYLProd(params) {
    return request({
        url: 'prod-api/generator/yuanLiao/list',
        method: 'GET',
        params
    })
}

// 获取养殖数据
export function getPig(params) {
    return request({
        url: 'prod-api/generator/szText/list',
        method: 'GET',
        params
    })
}

// 获取养殖产品数据
export function getPigProd(params) {
    return request({
        url: 'prod-api/generator/shengZhu/list',
        method: 'GET',
        params
    })
}

// 获取饲料数据
export function getFeed(params) {
    return request({
        url: 'prod-api/generator/slText/list',
        method: 'GET',
        params
    })
}
// 获取饲料产品数据
export function getFeedProd(params) {
    return request({
        url: 'prod-api/generator/siLiao/list',
        method: 'GET',
        params
    })
}

// 获取食品数据
export function getFood(params) {
    return request({
        url: 'prod-api/generator/spText/list',
        method: 'GET',
        params
    })
}

// 获取食品产品数据
export function getFoodProd(params) {
    return request({
        url: 'prod-api/generator/shiPing/list',
        method: 'GET',
        params
    })
}

// 留言
export function takeMessage(data) {
    return request({
        url: 'prod-api/generator/texts',
        method: 'POST',
        data
    })
}

// 文章详情
export function getArticleDetail(id) {
    return request({
        url: `prod-api/generator/actionHome/${id}`,
        method: 'GET'
    })
}

// 获取省市区
export function getArea() {
    return request({
        url: `prod-api/generator/administrativeDivision/listJson`,
        method: 'GET'
    })
}

// 获取工作类型
export function getJobType() {
    return request({
        url: `prod-api/generator/jobType/list`,
        method: 'GET'
    })
}

// 获取专项人才数据
export function getDedicated(params) {
    return request({
        url: `prod-api/generator/dedicatedModule/list`,
        method: 'GET',
        params
    })
}

// 获取员工风采数据
export function getPersonal(params) {
    return request({
        url: `prod-api/generator/forryedPerson/list`,
        method: 'GET',
        params
    })
}

// 获取招聘信息
export function getJob(params) {
    return request({
        url: `prod-api/generator/positionInfo/list`,
        method: 'GET',
        params
    })
}

// 获取模塊簡介
export function getIntorduce(params) {
    return request({
        url: `prod-api/generator/boardIntroduction/list`,
        method: 'GET',
        params
    })
}
