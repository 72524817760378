<!-- Footer -->
<template>
  <div class="container">
    <div class="footer">
      <div class="info">
        <div class="company">{{ bottomObj.companyName }}</div>
        <!--<div class="address">{{ bottomObj.companyAddress }}</div> -->
        <!-- <div class="rights">
          {{
            bottomObj.companyVersion &&
            bottomObj.companyVersion.split("贵公")[0]
          }}<br class="br" /><img
            style="vertical-align: text-top; margin-right: 4px"
            src="../assets/images/police.png"
            alt=""
        /><a href="https://beian.miit.gov.cn">{{
            bottomObj.companyVersion &&
            bottomObj.companyVersion.split(" ")[10] +
            bottomObj.companyVersion.split(" ")[11]
          }}</a>
        </div> -->
        <div class="rights">
          <span>{{
            bottomObj.companyVersion &&
            bottomObj.companyVersion.split("黔ICP")[0]
          }}</span>
          <a href="https://beian.miit.gov.cn" style="margin-left: 10px">{{
            bottomObj.companyVersion && bottomObj.companyVersion.split(" ")[6]
          }}</a>
          <img
            style="margin-right: 4px; vertical-align: middle; margin-left: 10px"
            src="../assets/images/police.png"
            alt=""
          />
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=贵公网安备52012302007221号"
            >{{
              bottomObj.companyVersion &&
              bottomObj.companyVersion.split(" ")[10] +
                bottomObj.companyVersion.split(" ")[11]
            }}</a
          >
        </div>
      </div>
      <div class="icon">
        <div class="qrcode">
          <img
            :src="
              bottomObj.hiringImg && bottomObj.hiringImg.indexOf('http') >= 0
                ? bottomObj.hiringImg
                : imgBaseUrl + bottomObj.hiringImg
            "
            alt=""
          /><br />
          <span>人才招聘</span>
        </div>
        <div class="qrcode">
          <img
            :src="
              bottomObj.tiktokImg && bottomObj.tiktokImg.indexOf('http') >= 0
                ? bottomObj.tiktokImg
                : imgBaseUrl + bottomObj.tiktokImg
            "
            alt=""
          /><br />
          <span>官方抖音</span>
        </div>
        <div class="qrcode">
          <img
            :src="
              bottomObj.wechatImg && bottomObj.wechatImg.indexOf('http') >= 0
                ? bottomObj.wechatImg
                : imgBaseUrl + bottomObj.wechatImg
            "
            alt=""
          /><br />
          <span>微信公众号</span>
        </div>
        <div class="lx">
          <span>业务咨询</span>
          <span>{{ bottomObj.workTime }}</span>
          <span>{{ bottomObj.phone }}</span>
        </div>
      </div>
    </div>
    <div class="mFooter">
      <div class="icon">
        <div class="qrcode">
          <img
            :src="
              bottomObj.wechatImg && bottomObj.wechatImg.indexOf('http') >= 0
                ? bottomObj.wechatImg
                : imgBaseUrl + bottomObj.wechatImg
            "
            alt="wechat"
          /><br />
          <span>微信公众号</span>
        </div>
        <div class="qrcode">
          <img
            :src="
              bottomObj.tiktokImg && bottomObj.tiktokImg.indexOf('http') >= 0
                ? bottomObj.tiktokImg
                : imgBaseUrl + bottomObj.tiktokImg
            "
            alt="tiktok"
          /><br />
          <span>官方抖音</span>
        </div>
        <div class="qrcode">
          <img
            :src="
              bottomObj.hiringImg && bottomObj.hiringImg.indexOf('http') >= 0
                ? bottomObj.hiringImg
                : imgBaseUrl + bottomObj.hiringImg
            "
            alt="hiring"
          /><br />
          <span>人才招聘</span>
        </div>
      </div>
      <div class="info">
        <div class="company">{{ bottomObj.companyName }}</div>
        <!-- <div class="address">{{ bottomObj.companyAddress }}</div> -->
        <div class="rights">
          <span>{{
            bottomObj.companyVersion &&
            bottomObj.companyVersion.split("黔ICP")[0]
          }}</span>
          <a href="https://beian.miit.gov.cn">{{
            bottomObj.companyVersion && bottomObj.companyVersion.split(" ")[6]
          }}</a>
          <img
            style="margin-right: 4px; vertical-align: middle"
            src="../assets/images/police.png"
            alt=""
          />
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=贵公网安备52012302007221号"
            >{{
              bottomObj.companyVersion &&
              bottomObj.companyVersion.split(" ")[10] +
                bottomObj.companyVersion.split(" ")[11]
            }}</a
          >
        </div>
        <!--        <div class="rights"><img src="../assets/images/police.png" alt=""><a href="https://beian.miit.gov.cn">{{ bottomObj.companyVersion }}</a></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getBottomData } from "@/api/interface";
export default {
  data() {
    return {
      param: {
        pageNum: 1,
        pageSize: 10,
      },
      bottomObj: {},
      imgBaseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  components: {},
  computed: {},
  mounted() {
    JSON.stringify(this.bottomObj) == "{}" && this.getData();
  },
  methods: {
    getData() {
      getBottomData(this.param).then((res) => {
        if (res.code === 200) {
          this.bottomObj = res.rows[0];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1074px) {
  .footer {
    width: 100%;
    height: 231px;
    background: #292a34;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 57px 0 33px;
    box-sizing: border-box;
    color: #ffffff;
    font-family: "PingFang SCR";
    .info {
      line-height: 22px;
      font-size: 16px;
      margin-top: 108px;
      .rights {
        margin-top: 10px;
        .br {
          display: none;
          @media screen and (max-width: 1453px) {
            display: block;
          }
        }
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    .icon {
      display: flex;
      justify-content: space-around;
      > div {
        margin-right: 15px;
        &.qrcode {
          text-align: center;
          font-size: 14px;
          img {
            height: 74px;
          }
        }
        &.lx {
          display: flex;
          flex-direction: column;
          margin-left: 27px;
          span {
            &:first-child {
              font-size: 26px;
            }
            &:nth-child(2) {
              font-size: 14px;
            }
            &:nth-child(3) {
              font-size: 26px;
              font-family: "Avenir Next Bold Italic";
            }
          }
        }
      }
    }
  }
  .mFooter {
    display: none;
  }
}
@media screen and (max-width: 1074px) {
  .footer {
    display: none;
  }
  .mFooter {
    padding: 0.24rem 0.2rem 0.8rem 0.42rem;
    box-sizing: border-box;
    font-size: 0.24rem;
    color: #333333;
    border-top: 0.02rem solid #e2e2e2;
    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .qrcode {
        text-align: center;
        img {
          width: 1.46rem;
          height: 1.46rem;
        }
        span {
          margin-top: 0.12rem;
        }
      }
    }
    .info {
      margin-top: 0.72rem;
      .address {
        margin: 0.12rem 0 0.28rem 0;
      }
      .rights {
        a {
          color: #292a34;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
