import Vue from 'vue'
function setRem() {
    let deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0
    console.log(deviceWidth)
    if (deviceWidth >= 1074) {
        document.documentElement.style.fontSize = deviceWidth / 14.4 + 'px' // 1440px除以14.4
    } else {
        document.documentElement.style.fontSize = deviceWidth / 7.8 + 'px'  // 780px除以7.8
    }
    Vue.prototype.deviceWidth = deviceWidth
}

setRem()

window.onresize = () => {
    setRem()
}
